
import { ref, computed, onMounted, defineComponent } from "vue";
import axios from "axios";
import { Geolocation } from "@capacitor/geolocation";

import Markdown from "vue3-markdown-it";
import "highlight.js/styles/monokai.css";

import {
  IonContent,
  IonList,
  IonFooter,
  IonToolbar,
  IonItem,
  IonInput,
  IonButton,
  IonSpinner,
  IonIcon,
} from "@ionic/vue";

import {
  personCircleOutline,
  flowerOutline,
  paperPlaneOutline,
} from "ionicons/icons";

export default defineComponent({
  components: {
    IonContent,
    IonList,
    IonFooter,
    IonToolbar,
    IonItem,
    IonInput,
    IonButton,
    IonSpinner,
    IonIcon,
    Markdown
  },

  setup() {
    const loading = ref(false);
    const question = ref();
    const answer = ref();
    const maxToken = ref();
    const temperature = ref();
    const messages = ref<any>([]);
    const messagesUser = ref<any>([]);
    const messagesUA = computed(() => {
      return messages.value.filter(
        (m) => m.role == "user" || m.role == "assistant"
      );
    });
    const maxLen = 1800;
    const size = "ada";
    const tgl = ref(false);
    const df = ref();
    const context = ref("");
    const contextUrl = ref({});
    const tc: string[] = [];
    const joinContext = ref("");
    const askQuestion = async () => {
      try {
        loading.value = true;
        await createContext();
        tc.push(context.value);
        const uniqueArray = [...new Set(tc)];
        joinContext.value = uniqueArray.join("\n");
        if (joinContext.value.length > 5000) {
          //joinContext.value=''
          tc.length = 0;
        }
        messages.value[0].content = instructBot.value + " " + joinContext.value;
        console.log("system instruct context", messages.value[0]);
        messagesUser.value.push({ role: "user", content: question.value });
        messages.value.push({ role: "user", content: question.value });
        //messages.value.push({ role: 'user', content: "Context: "+context.value+' Question:'+ question.value});
        //messages.value.push({ role: 'user', content: question.value},{ role: 'system', content: context.value});
        const msg = messages.value.map((message) => ({ ...message }));

        const functions = [
          {
            name: "sendMailToUser",
            description: "Send an e-mail to a given address",
            parameters: {
              type: "object",
              properties: {
                to: {
                  type: "string",
                  description: "A vaild e-mail addresse",
                },
                subject: {
                  type: "string",
                  description: "A JSON valid string",
                },
                body: {
                  type: "string",
                  description: "A JSON valid string",
                },
              },
              required: ["to"],
            },
          },
          {
            name: "onThisDay",
            description:
              "On this day serves infos on what happend this day in history. The parameter is an us-en formatted datastring with the year 2020 appended",
            parameters: {
              type: "object",
              properties: {
                thisday: {
                  type: "string",
                  description: "A string for the given date.",
                },
              },
              required: ["thisday"],
            },
          },
          {
            name: "datetimeFct",
            description: "The current date and time",
            parameters: {
              type: "object",
              properties: {
                thisday: {
                  type: "string",
                  description: "A day and time string",
                },
              },
              required: ["date"],
            },
          },
          {
            name: "botQuery",
            description: "Use this for mcon topics",
            parameters: {
              type: "object",
              properties: {
                sql: {
                  type: "string",
                  description: "Create an SQL query. Do not create the whole query only complete the where WHERE part of the statement like so field LIKE 'text'. These are the fields for the where part query: bodytext, header, tstamp. Always use bodytext, optionally use header and if needed tstamp for a timestamp",
                },
              },
              required: ["sql"],
            },
          },
          {
            name: "getGasPrices",
            description: "Search for gasoline prices in a given city or on geolocation data from user device. If city empty, ask user to activate geolocation.",
            parameters: {
              type: "object",
              properties: {
                city: {
                  type: "string",
                  description: "A name of a city. If city empty, ask user to activate geolocation.",
                },
                radius: {
                  type: "number",
                  description: "A number for the radius",
                },
                usegeo: {
                  type: "boolean",
                  description: "Use geolocation data from device. Can be true or false.",
                },
              },
              //required: ["city"],
            },
          },
        ];

        const response = await axios.post(
          process.env.VUE_APP_OPENAI_URL + "/v1/chat/completions",
          {
            model: "gpt-3.5-turbo-0613",
            temperature: temperature.value || 0.5,
            max_tokens: maxToken.value || 60,
            messages: msg,
            functions: functions,
            function_call: "auto",
            //messages: [{"role": "system", "content": instructBot.value}]
            //messages: `Answer the question based on the context below, and if the question can't be answered based on the context, say "I don't know"\n\nContext: ${context}\n\n---\n\nQuestion: ${question.value}\nAnswer:`,
          },
          {
            headers: {
              Authorization: "Bearer " + process.env.VUE_APP_OPENAI_API_KEY,
            },
          }
        );
        //answer.value = response.data.choices[0].message.content;
        //messages.value[0] = response.data.choices[0].message;

        /*
        const response = await axios.post("https://miconomy.com?type=123", {
          msg: msg,
        });
        */

        //For User output as openai denys additional elements
        //const rm = { ...response.data.choices[0].message };
        //messagesUser.value.push(Object.assign(rm, contextUrl.value));

        const res = response.data.choices[0].message;
        let fctaw = res.content;

        //messages.value.push(res);

        // call a function
        console.log("response", res);
        if (res.function_call) {
          const available_functions = {
            sendMailToUser: sendMailToUser,
            onThisDay: onThisDay,
            datetimeFct: datetimeFct,
            botQuery: botQuery,
            getGasPrices: getGasPrices
          };
          const function_name = res["function_call"]["name"];
          const function_to_call = available_functions[function_name];
          let argumentsString = res["function_call"]["arguments"];

          // This is needed if argumentsString is cropped and an invalid json is responded
          if (!argumentsString.endsWith("}")) {
            if (argumentsString.endsWith('"')) {
              argumentsString = argumentsString + "\n}";
            } else {
              argumentsString = argumentsString + '"\n}';
            }
          }

          //const function_args = argumentsString
          const function_args = JSON.parse(argumentsString);
          console.log("function_args", function_args);

          let function_response = "";
          if (function_name == "sendMailToUser") {
            function_response = await function_to_call(
              function_args.to,
              function_args.subject,
              function_args.body
            );
          }
          if (function_name == "onThisDay") {
            function_response = await function_to_call(function_args.thisday);
          }
          if (function_name == "datetimeFct") {
            function_response = await function_to_call(function_args.date);
          }
          if (function_name == "botQuery") {
            function_response = await function_to_call(function_args.sql);
          }
          if (function_name == "getGasPrices") {
            function_response = await function_to_call(
              function_args.city,
              function_args.radius,
              function_args.usegeo
              );
          }

          //const function_args = JSON.parse(res["function_call"]["arguments"])

          messages.value.push({
            role: "function",
            name: function_name,
            content: function_response,
          });
          messagesUser.value.push({
            role: "function",
            name: function_name,
            content: function_response,
          });
          console.log("Fct args", function_args);
          console.log("Fct response", function_response);
          console.log("Msg Fct", messages.value);
          //---
          const second_response = await axios.post(
            process.env.VUE_APP_OPENAI_URL + "/v1/chat/completions",
            {
              model: "gpt-3.5-turbo-0613",
              temperature: 0.5,
              max_tokens: 60,
              messages: messages.value,
            },
            {
              headers: {
                Authorization: "Bearer " + process.env.VUE_APP_OPENAI_API_KEY,
              },
            }
          );
          loading.value = false;
          console.log(
            "Msg Second",
            second_response.data.choices[0].message.content
          );
          fctaw = second_response.data.choices[0].message.content;
          res.content = fctaw;
          //return fctaw
          //--------
        }
        // .call a function
        console.log("answer", fctaw);
        console.log("second resposne", res);
        messages.value.push(res);
        const rm = { ...response.data.choices[0].message };
        messagesUser.value.push(Object.assign(rm, contextUrl.value));

        putContent(messages.value);
        loading.value = false;
        question.value = "";
        //let wh = window.innerHeight
        //window.scrollTo(0,wh)
      } catch (error) {
        //joinContext.value=''
        //context.value=''
        //messages.value[0].content=''
        tc.length = 0;
        loading.value = false;
        messagesUser.value.push({
          role: "assistant",
          content:
            "Es ist ein Fehler aufgetreten. Bitte stellen Sie die Frage erneut.",
        });
        console.error(error);
      }
    };
    // Create Context Vec Store
    const createContext2 = async () => {
      const response = await axios.post(
        process.env.VUE_APP_OPENAI_URL +
        "/v1/engines/text-embedding-ada-002/embeddings",
        {
          input: question.value,
        },
        {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_OPENAI_API_KEY,
          },
        }
      );

      //Emb from Vector DB
      const vo = JSON.stringify(response.data.data[0].embedding);
      const min = await getEmbContent(vo);

      /*
      const response = await axios.post("https://miconomy.com?type=124", {
        msg: question.value,
      });
      const qEmbeddings = new Float32Array(response.data.data[0].embedding);
       */

      const ctxs: string[] = [];
      const urls: string[] = [];
      const tlts: string[] = [];
      let minVal = 0;
      min.documents.forEach((v) => {
        answer.value = v.value.text;
        ctxs.push(v.value.text);
        minVal = v.value.dist
        if (minVal < 0.2) {
          urls.push(v.value.url);
          tlts.push(v.value.title);
        }
      });
      context.value = ctxs.join("\n ### \n");
      const uniqueUrls = [...new Set(urls)];
      const uniqueTlts = [...new Set(tlts)];
      contextUrl.value = { url: uniqueUrls, title: uniqueTlts };
    };
    // .Creeate Context Vec Store

    const createContext = async () => {
      const response = await axios.post(
        process.env.VUE_APP_OPENAI_URL +
        "/v1/engines/text-embedding-ada-002/embeddings",
        {
          input: question.value,
        },
        {
          headers: {
            Authorization: "Bearer " + process.env.VUE_APP_OPENAI_API_KEY,
          },
        }
      );
      const qEmbeddings = new Float32Array(response.data.data[0].embedding);

      //Emb from Vector DB
      /*
      const vo = JSON.stringify(response.data.data[0].embedding)
      const vec = await getEmbContent(vo)
      console.log('Vector',vec)
      */
      //.Emb from Vector DB

      /*
      const response = await axios.post("https://miconomy.com?type=124", {
        msg: question.value,
      });
      const qEmbeddings = new Float32Array(response.data.data[0].embedding);
       */
      console.log("embeddings", qEmbeddings);

      function distancesFromEmbeddings(embeddings1, embeddings2) {
        const norm1 = Math.sqrt(
          embeddings1.reduce((acc, val) => acc + val * val, 0)
        );
        const norm2 = Math.sqrt(
          embeddings2.reduce((acc, val) => acc + val * val, 0)
        );
        const dotProduct = embeddings1.reduce(
          (acc, val, i) => acc + val * embeddings2[i],
          0
        );
        return 1 - dotProduct / (norm1 * norm2);
      }

      const d = {};
      for (const [key, value] of Object.entries(df.value.embeddings)) {
        //console.log(`${key}: ${value}`);
        d[key] = distancesFromEmbeddings(qEmbeddings, value);
      }
      df.value.distances = d;
      console.log("Dist", df.value.distances);

      const obj = df.value.distances;
      const min = Object.keys(obj)
        .sort((a, b) => obj[a].toString().localeCompare(obj[b].toString()))
        .slice(0, 3);

      console.log("Min", min);
      console.log("Dist", obj);
      const ctxs: string[] = [];
      const urls: string[] = [];
      const tlts: string[] = [];
      let minVal = 0;
      min.forEach((v) => {
        minVal = obj[v];
        answer.value = df.value.text[v];
        ctxs.push(df.value.text[v]);
        if (minVal < 0.2) {
          urls.push(df.value.url[v]);
          tlts.push(df.value.title[v]);
        }
      });
      context.value = ctxs.join("\n ### \n");
      const uniqueUrls = [...new Set(urls)];
      const uniqueTlts = [...new Set(tlts)];
      contextUrl.value = { url: uniqueUrls, title: uniqueTlts };
    };
    const getEmbContent = async (q) => {
      //https://sfexpressapp.azurewebsites.net/users
      //localhost:3000
      //http://miconomy.com/redis 
      try {
        const response = await axios.post(
          "https://sfexpressapp.azurewebsites.net/users",
          {
            q: q,
          },
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        );
        console.log("Emb Data", response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    const getEmbContentGet = async (q) => {
      try {
        const response = await axios.get("http://miconomy.com/redis", {
          params: {
            q: q,
          },
        });
        console.log("Emb Data", response.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    const mounted = async () => {
      try {
        const response = await axios.get(
          "https://miconomy.com/fileadmin/dist/uploadL3.json"
        );

        df.value = response.data;
        console.log("Data", df.value);
      } catch (error) {
        console.error(error);
      }
    };
    const instructBot = ref();
    const instructBotFct = async () => {
      await axios
        .get("https://miconomy.com/fileadmin/dist/botInstructions.json")
        .then((response) => {
          instructBot.value = response.data.text;
          maxToken.value = response.data.maxToken;
          temperature.value = response.data.temperature;
          messages.value.push({ role: "system", content: response.data.text });
          console.log("initBot", response.data);
        })
        .catch((error) => {
          console.log("initBot Error", error);
        });
      mounted();
    };
    const putContent = async (v) => {
      await axios
        .post(
          "https://miconomy.com?type=122",
          {
            co: v,
          },
          {
            headers: { "Content-Type": `text/html` },
            //headers: { "Content-Type": `application/json` }
          }
        )
        .then((response) => {
          console.log("putbContent", response);
          scrollToBottom();
        })
        .catch((error) => {
          console.log("putContent Error", error);
        });
    };

    /*
    const user = ref('B')
    const getUser = async () => {
  await axios
    .get("https://lea.miconomy.com", {
      params: {
        type: 1102,
      },
    })
    .then((response) => {
      user.value = response.data.username?response.data.username:'Nicht angemeldet';
      console.log("Resp", response);
    })
    .catch((error) => {
      user.value = "Kein Name";
      console.log('Error',error)
    });
};
*/
    const getContent = () => {
      return document.querySelector("ion-content");
    };

    const scrollToBottom = () => {
      getContent()?.scrollToBottom(500);
    };

    const sendMailToUser = async (
      to,
      subject = "Post von LEA",
      body = "Kein Text"
    ) => {
      const response = await axios.get("https://miconomy.com", {
        params: {
          type: 126,
          to,
          subject,
          body,
        },
      });
      return (
        "An " +
        response.data.to +
        " mit dem Betreff: " +
        response.data.subject +
        " und dem Text: " +
        response.data.body
      );
    };

    const onThisDay = async (thisday) => {
      console.log("thisDay", thisday);

      const thisYear = new Date();
      const ty = thisYear.getFullYear();
      const td = thisday + ", " + ty;
      console.log("td", td);
      const today = new Date(td);
      console.log("today Response new Date", today);
      let resp = "";
      if (today.toDateString() != "Invalid Date") {
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        const url = `https://api.wikimedia.org/feed/v1/wikipedia/en/featured/${year}/${month}/${day}`;
        try {
          const response = await axios.get(url, {
            params: {
              type: thisday,
            },
          });

          response.data.onthisday.slice(0, 2).forEach((v) => {
            resp += "Year: " + v.year + " Context: " + v.text + "\n\n";
          });
        } catch (error) {
          console.log("onthisday error", error);
          //resp = JSON.stringify(error)
        }
      } else {
        resp =
          "Die Anfrage konnte nicht ausgeführt werden. Bitte prüfen Sie, ob Tag und Monat korrekt sind.";
      }

      console.log("On this day", resp);
      return resp;
    };
    const botQuery = async (sql) => {
      const url = `https://miconomy.com`;
      let resp
      try {
        const response = await axios.get(url, {
          params: {
            type: 128,
            q: encodeURI(sql)
          },
        });

        resp = response.data;
      } catch (error) {
        console.log("onthisday error", error);
      }

      console.log("Query Bot Response", resp);
      return JSON.stringify(resp);
    };
    const datetimeFct = (dte) => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      const hours = String(today.getHours());
      const minutes = String(today.getMinutes());
      const seconds = String(today.getSeconds());
      const dayName = String(
        today.toLocaleDateString("de-de", { weekday: "long" })
      );

      const dateObj = {
        day: day,
        month: month,
        year: year,
        hours: hours,
        minutes: minutes,
        secounds: seconds,
        dayName: dayName,
        input: dte,
      };

      return JSON.stringify(dateObj);
    };

    /* Not in use. Called in getGasPrice
    const getCurrentPosition = async (event = null) => {
      const options = {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0,
      };
      const pos = await Geolocation.getCurrentPosition(options);
      console.log('*Pos', pos)
    };
    */

    const getGasPrices = async (city,radius,usegeo) => {
      let geoData:any=[{lat:0,lon:0}]
      console.log('curPosData outside if condition ', usegeo)
      if(usegeo){
        console.log('curPosData in if condition ', usegeo)
      const options = {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 0,
      };
      const pos = await Geolocation.getCurrentPosition(options);
      console.log('CurPos',pos)
      
       console.log('pos coords', pos.coords.longitude)
       geoData[0].lat = pos.coords.latitude
       geoData[0].lon = pos.coords.longitude
      }else{
        geoData = await getGeoData(city)
      }
      

      if(!city && !usegeo){
        return JSON.stringify('You have access to realtime gasoline prices but you need more informetion such as city oder geo data. There is no city given. Should I activate Geolocation on your divice?')
      }

      //geoData = await getGeoData(city)
      console.log('getGeoData ',geoData)
      const items = await getGasPricesInner(geoData,radius)
      return JSON.stringify(items)
    }

    const getGeoData = async (city) => {
      console.log('Get params for GeoData', city)
      let items: any = []
      const params = {
        format: "json",
        limit: 1,
        q: city
      };
      await axios
        .get("https://nominatim.openstreetmap.org/search", {
          params,
        })
        .then((response) => {
          items = response.data
          console.log("Items GeoData", items);
        })
        .catch((err) => {
          console.log("Error Items GeoData", err);
        });

      return items
    }

    const getGasPricesInner = async (geoData,radius=2) => {
      console.log('Radius in getGasPriceInner', radius)
      console.log('Gas City', geoData)
      let items:any = []
      const params = {
        lat: geoData[0].lat,
        lng: geoData[0].lon,
        rad: radius,
        type: "all",
        apikey: process.env.VUE_APP_TANKERKOENIG_API_KEY,
      };

      await axios
        .get(process.env.VUE_APP_TANKERKOENIG_URL, {
          params,
        })
        .then((response) => {
          loading.value = false;
          console.log("Prices", response.data.stations);
          items = response.data.stations
        })
        .catch((err) => {
          loading.value = false;
          console.log("PricesError", err);
        });

       const d = new Date();
       const ve:any = []
       const lgh = items.length > 6 ? 7 : items.length
       items.slice(0,lgh).forEach((v)=>{
            ve.push('Name: '+v.brand+' '+v.name+' Diesel: '+v.diesel+' E5: '+v.e5+' E10: '+v.e10+'postCode: '+v.postCode+' place: '+v.street+' street '+v.street+' isOpen: '+v.isOpen)
          })
          ve.push('Datum: '+d)
      console.log('items ',ve)
       
      return JSON.stringify(ve)
    };
    onMounted(() => {
      instructBotFct();
    });
    return {
      loading,
      question,
      answer,
      context,
      contextUrl,
      joinContext,
      messages,
      messagesUser,
      messagesUA,
      askQuestion,
      createContext,
      mounted,
      paperPlaneOutline,
      personCircleOutline,
      flowerOutline,
      tgl,
      df,
    };
  },
});
