import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"list-style":"none","padding-left":"0px","margin-left":"0px"} }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messagesUser, (msg) => {
              return (_openBlock(), _createElementBlock("div", {
                key: msg.id
              }, [
                (msg.content && (msg.role == 'assistant' || msg.role == 'user'))
                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          icon: msg.role == 'user' ? _ctx.personCircleOutline : _ctx.flowerOutline
                        }, null, 8, ["icon"]),
                        _createElementVNode("div", null, [
                          _createElementVNode("p", null, [
                            _createVNode(_component_Markdown, {
                              source: msg.content
                            }, null, 8, ["source"])
                          ]),
                          (msg.role == 'assistant')
                            ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                                _createElementVNode("ul", _hoisted_2, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(msg.url, (u, i) => {
                                    return (_openBlock(), _createElementBlock("li", { key: u }, [
                                      _createElementVNode("a", {
                                        href: u,
                                        target: "_blank"
                                      }, _toDisplayString(msg.title[i]), 9, _hoisted_3)
                                    ]))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  onKeypress: _withKeys(_withModifiers(_ctx.askQuestion, ["prevent"]), ["enter"]),
                  modelValue: _ctx.question,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.question) = $event)),
                  placeholder: "Eingabe..."
                }, null, 8, ["onKeypress", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.askQuestion }, {
                  default: _withCtx(() => [
                    (_ctx.loading)
                      ? (_openBlock(), _createBlock(_component_ion_spinner, {
                          key: 0,
                          style: {"width":"17px"},
                          name: "lines-sharp-small"
                        }))
                      : (_openBlock(), _createBlock(_component_ion_icon, {
                          key: 1,
                          slot: "icon-only",
                          icon: _ctx.paperPlaneOutline
                        }, null, 8, ["icon"]))
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}